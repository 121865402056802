import React, { useEffect, useState, useReducer, useCallback, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, useAccount, useMsal } from '@azure/msal-react';

import { theme as defaultTheme } from './theme';
import SideBar from './components/SideBar';
import GeographicLandscape from './pages/GeographicLandscape';
import PayerLandscape from './pages/PayerLandscape';
import PayerLandscapeGroupPractice from './pages/PayerLandscapeGroupPractice';
import PayerTopHCPs from './pages/PayerTopHCPs';
import PayerTopGroupPractices from './pages/PayerTopGroupPractices';
import RepMessages from './pages/RepMessages';
import RepMessagesGroupPractice from './pages/RepMessagesGroupPractice';
import { getConfiguration } from './api/configuration';
import AppPages from './AppPages';
import AppContext from './AppContext';
import AdminPanel from './pages/AdminPanel';
import { loginRequest } from './authConfig';

import { getUser } from './api/user';
import { getTemplate } from './util/stringTemplate';
import { getLoggedInUser, samlRedirect } from './api/AGIAPI';

import { SelectedFiltersContextProvider } from './util/SelectedFiltersContext';
import HCPOpportunityScore from './pages/HCPOpportunityScore';
import { reports } from './reports';

const SET_CONFIGURATION = 'SET_CONFIGURATION';
const START_OP = 'START_OP';
const FINISH_OP = 'FINISH_OP';
const SET_USER = 'SET_USER';

const regionGeographyKind = 1000000;
const areaGeographyKind = 2000000;
const districtGeographyKind = 3000000;
const territoryGeographyKind = 4000000;
const stateTerritoryGeographyKind = 5000000;
const nationalGeographyKind = 100000000000;
const stateGeographyKind = 200000000000;
const zip3GeographyKind = 300000000000;
const countyGeographyKind = 400000000000;
const zip5GeographyKind = 500000000000;
const HRRGeographyKind = 600000000000;

const samlUrl = process.env.REACT_APP_SAML_URL;
const samlEnabled = samlUrl ? true : false;

const geographyKindIds = {
	Region: regionGeographyKind,
	Area: areaGeographyKind,
	District: districtGeographyKind,
	Territory: territoryGeographyKind,
	StateTerritory: stateTerritoryGeographyKind,
	'State-Territory': stateTerritoryGeographyKind,
	National: nationalGeographyKind,
	State: stateGeographyKind,
	Zip3: zip3GeographyKind,
	County: countyGeographyKind,
	Zip5: zip5GeographyKind,
	HRR: HRRGeographyKind,
};

const geographyKinds = Object.entries(geographyKindIds).map(([label, id]) => ({ label, id }));

function fixupConfiguration(configuration) {
	const { mapStyle: style, geoLevelOptions, preserveMapSource, version = 0 } = configuration;

	let availableGeographyKinds = [];

	if (version >= 1) {
		availableGeographyKinds = configuration.geographyKinds.map((gk) => ({
			id: gk.id,
			label: gk.label,
		}));
	}

	let updatedLayers = style.layers;

	// Deprecated
	// Previous versions of Data Load would use the Geography kind LABEL
	// as the layer name. But we need the KIND ID instead
	// so this code would do a replacement of eg Area_labels to 10000_labels
	if (version < 1) {
		updatedLayers = [
			...style.layers.map((l) => {
				let id = l.id.toString();
				for (const { label, id: gid } of geographyKinds) {
					if (l.id === label) {
						id = gid.toString();
						availableGeographyKinds.push({ label, id: gid });
						break;
					}

					if (l.id === `${label}_labels`) {
						id = `${gid}_labels`;
						break;
					}
				}

				return {
					...l,
					id: id,
					'source-layer': id,
					filter: false,
				};
			}),
		];
	}

	// Move national to first so it shows beneath all others on map
	const nationalLayerIdx = updatedLayers.findIndex((l) => l.id === nationalGeographyKind.toString());
	if (0 <= nationalLayerIdx) {
		const nationalLayer = updatedLayers[nationalLayerIdx];
		nationalLayer.filter = true;
		updatedLayers.splice(nationalLayerIdx, 1);
		updatedLayers.unshift(nationalLayer);
	}

	const updatedMapStyle = {
		...style,
		sources: {
			...style.sources,
			azure: {
				...style.sources.azure,
				tiles: preserveMapSource
					? style.sources.azure.tiles
					: ['https://cdnp-pfm-agi-mbtiles-stg-eus2.azureedge.net/services/geography-demo-12-20-2021-01/tiles/{z}/{x}/{y}.pbf'],
			},
		},
		layers: updatedLayers,
	};

	let updatedGeoLevelOptions = [...geoLevelOptions];
	if (version < 1) {
		// Deprecated
		// Previous versions of data load would have
		// the geography kind NAME as the VALUE, but we need
		// the the KIND ID to query API
		updatedGeoLevelOptions = geoLevelOptions.map((opt) => ({
			...opt,
			value: opt.value in geographyKindIds ? geographyKindIds[opt.value] : opt.value,
		}));
	}

	// Add NATIONAL entry to each option
	const nationalGeographyKindObj = availableGeographyKinds.find((gk) => gk.id === nationalGeographyKind);
	if (nationalGeographyKindObj) {
		for (const opt of updatedGeoLevelOptions) {
			const { options } = opt;
			options.unshift({
				value: null,
				label: nationalGeographyKindObj.label,
			});
		}
	}

	return {
		...configuration,
		mapStyle: updatedMapStyle,
		geoLevelOptions: updatedGeoLevelOptions,
		geographyKinds: availableGeographyKinds,
	};
}

function reducer(state, action) {
	switch (action.type) {
		case SET_CONFIGURATION: {
			const newConfiguration = fixupConfiguration(action.payload);
			return {
				...state,
				configuration: newConfiguration,
			};
		}
		case SET_USER: {
			return {
				...state,
				user: action.payload,
			};
		}
		case START_OP: {
			return {
				...state,
				pendingOps: state.pendingOps + 1,
			};
		}
		case FINISH_OP: {
			return {
				...state,
				pendingOps: state.pendingOps - 1,
			};
		}
		default:
			console.error('unknown action:', action);
			return state;
	}
}

function App({ theme = defaultTheme }) {
	const [isSamlRedirect, setIsSamlRedirect] = useState(false);
	const [samlAccount, setSamlAccount] = useState();
	useEffect(() => {
		if (samlEnabled) {
			getLoggedInUser()
				.then((user) => {
					if (!user) {
						setIsSamlRedirect(true);
						return;
					}
					setSamlAccount(user);
				})
				.catch(() => {
					setIsSamlRedirect(true);
				});
		}
	}, []);

	const { accounts } = useMsal();

	const msalAccount = useAccount(accounts[0] || {});

	const account = samlEnabled ? samlAccount : msalAccount;
	
	const [appContext, dispatch] = useReducer(reducer, {
		configuration: null,
		pendingOps: 0,
		user: null,
	});

	const startOp = useCallback(
		(op) => {
			dispatch({
				type: START_OP,
			});
			return op().finally(() => {
				dispatch({
					type: FINISH_OP,
				});
			});
		},
		[dispatch]
	);

	const pendingOps = appContext.pendingOps;
	const isLoading = pendingOps > 0;

	useEffect(() => {
		if (!account) {
			return;
		}

		let unmounted = false;
		startOp(async () => {
			const result = await getConfiguration();

			if (samlEnabled) {
				if (account?.email) {
					dispatch({
						type: SET_USER,
						payload: account,
					});
				}
			} else {
				if (account?.idTokenClaims?.emails?.length > 0) {
					const user = await getUser(account.idTokenClaims.emails[0].toLowerCase());
					dispatch({
						type: SET_USER,
						payload: user,
					});
				}
			}

			if (unmounted) {
				return;
			}

			// Upcase heat measure property references for case-insensitive matching
			const upcasedHeatMeasures = Object.fromEntries(
				Object.entries(result.heatMeasures).map(([id, value]) => [
					id,
					{
						...value,
						property: value.property.toUpperCase(),
						categoryProperty: value.categoryProperty.toUpperCase(),
					},
				])
			);

			const upcasedResult = {
				...result,
				heatMeasures: upcasedHeatMeasures,
			};

			dispatch({
				type: SET_CONFIGURATION,
				payload: upcasedResult,
			});
		});

		return () => (unmounted = true);
	}, [startOp, dispatch, account]);

	const mainPages = useMemo(() => {
		const items = [
			{
				title: theme.titles.geoLandscape,
				icon: theme.icons.geoLandscape,
				path: reports.geoLandscape.path,
				component: GeographicLandscape,
				menuItem: theme.menuItems.geoLandscape,
				reportId: reports.geoLandscape.id,
			},
			{
				title: theme.titles.payerLandscape,
				icon: theme.icons.payerLandscape,
				path: reports.payerLandscape.path,
				component: PayerLandscape,
				menuItem: theme.menuItems.payerLandscape,
				reportId: reports.payerLandscape.id,
			},
			{
				title: theme.titles.payerTopHcps,
				icon: theme.icons.payerTopHcps,
				path: reports.payerTopHcps.path,
				component: PayerTopHCPs,
				menuItem: theme.menuItems.payerTopHcps,
				reportId: reports.payerTopHcps.id,
			},
			{
				title: theme.titles.repMessages,
				icon: theme.icons.repMessages,
				path: reports.repMessages.path,
				component: RepMessages,
				menuItem: theme.menuItems.repMessages,
				reportId: reports.repMessages.id,
			},
			{
				title: theme.titles.hcpOpportunityScore,
				icon: theme.icons.hcpOpportunityScore,
				path: reports.hcpOpportunityScore.path,
				component: HCPOpportunityScore,
				menuItem: theme.menuItems.hcpOpportunityScore,
				reportId: reports.hcpOpportunityScore.id,
			},
			{
				title: theme.titles.payerLandscapeGroupPractice,
				icon: theme.icons.payerLandscapeGroupPractice,
				path: reports.payerLandscapeGroupPractice.path,
				component: PayerLandscapeGroupPractice,
				menuItem: theme.menuItems.payerLandscapeGroupPractice,
				reportId: reports.payerLandscapeGroupPractice.id,
			},
			{
				title: theme.titles.payerTopGroupPractices,
				icon: theme.icons.payerTopGroupPractices,
				path: reports.payerTopGroupPractices.path,
				component: PayerTopGroupPractices,
				menuItem: theme.menuItems.payerTopGroupPractices,
				reportId: reports.payerTopGroupPractices.id,
			},
			{
				title: theme.titles.repMessagesGroupPractice,
				icon: theme.icons.repMessagesGroupPractice,
				path: reports.repMessagesGroupPractice.path,
				component: RepMessagesGroupPractice,
				menuItem: theme.menuItems.repMessagesGroupPractice,
				reportId: reports.repMessagesGroupPractice.id,
			},
		];
		if (!appContext.configuration?.availableReports) {
			return items;
		}
		return items.filter((item) => appContext.configuration.availableReports.includes(item.reportId));
	}, [theme, appContext.configuration]);

	const secondaryPages = useMemo(() => {
		const secondaryPages = [];
		const externalPODlink = process.env.REACT_APP_EXTERNAL_POD_LINK;

		//this one is default POD link alwayes there
		if (externalPODlink) {
			secondaryPages.push({
				menuItem: theme.menuItems.printOnDemandPortal,
				icon: theme.icons.printOnDemandPortal,
				path: externalPODlink,
				isExternal: true,
				component: null,
				tooltip: theme.menuItems.PODTooltip,
			});
		}

		//if title and url is passed through theme.js i.e. from index.js in client project
		var podDetails = theme.podLinks;
		if (podDetails) {
			if (appContext?.user?.role === 'admin') {
				//If admin, show all the POD links
				for (let i = 0; i < podDetails.length; i++) {
					secondaryPages.push({
						menuItem: podDetails[i].title,
						icon: theme.icons.printOnDemandPortal,
						path: podDetails[i].url,
						isExternal: true,
						component: null,
						tooltip: podDetails[i].tooltip,
					});
				}
			} else {
				var title2 = '';
				var url2 = '';
				var tooltip2 = '';
				//get all productId that user have access
				var productArrayNumber = [];
				if (appContext.configuration !== null) {
					const productString = appContext.configuration.userProductId;
					if (productString !== undefined) {
						var productArray = productString.split(',').map((value) => value.trim());
						productArrayNumber = productArray.map((str) => parseInt(str));
					}
				}
				//find all the product in podDetails array either in one or in same array
				const filteredProduct = podDetails.filter((item) => {
					return (
						productArrayNumber.every((value) => item.products.includes(value)) || productArrayNumber.some((value) => item.products.includes(value))
					);
				});

				if (filteredProduct.length > 0) {
					filteredProduct.forEach((p) => {
						title2 = p.title;
						url2 = p.url;
						tooltip2 = p.tooltip;

						secondaryPages.push({
							menuItem: title2,
							icon: theme.icons.printOnDemandPortal,
							path: url2,
							isExternal: true,
							component: null,
							tooltip: tooltip2,
						});
					});
				}
			}
		}

		if (appContext?.user?.role === 'admin') {
			secondaryPages.push({
				title: theme.titles.admin,
				icon: theme.icons.admin,
				path: '/admin',
				isExternal: false,
				component: AdminPanel,
				menuItem: theme.menuItems.admin,
			});
		}

		return secondaryPages;
	}, [theme, appContext.user, appContext.configuration]);

	const pages = useMemo(() => [...mainPages, ...secondaryPages], [mainPages, secondaryPages]);

	const sidebarTitle = useMemo(() => {
		const defaultTitle = 'Access Genius Insights';

		if (!appContext.configuration) {
			return defaultTitle;
		}

		return getTemplate(appContext.configuration.stringTemplates, 'AGI.SidebarTitle') || defaultTitle;
	}, [appContext.configuration]);

	if (samlEnabled) {
		if (isSamlRedirect) {
			// prevent UI flashing before SAML login
			samlRedirect();
			return null;
		}

		return (
			<ThemeProvider theme={theme}>
				<SelectedFiltersContextProvider>
					<AppContext.Provider
						value={{
							configuration: appContext.configuration,
							startOp: startOp,
							user: appContext.user,
						}}>
						<Router>
							<AppPages pages={pages} isLoading={isLoading} />
							<SideBar account={account} mainLinks={mainPages} secondaryLinks={secondaryPages} title={sidebarTitle} />
						</Router>
					</AppContext.Provider>
				</SelectedFiltersContextProvider>
			</ThemeProvider>
		);
	} else {
		return (
			<ThemeProvider theme={theme}>
				<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
					<AuthenticatedTemplate>
						<SelectedFiltersContextProvider>
							<AppContext.Provider
								value={{
									configuration: appContext.configuration,
									startOp: startOp,
									user: appContext.user,
								}}>
								<Router>
									<AppPages pages={pages} isLoading={isLoading} />
									<SideBar account={account} mainLinks={mainPages} secondaryLinks={secondaryPages} title={sidebarTitle} />
								</Router>
							</AppContext.Provider>
						</SelectedFiltersContextProvider>
					</AuthenticatedTemplate>
				</MsalAuthenticationTemplate>
			</ThemeProvider>
		);
	}
}

export default App;
